<template>
	<div class="login_wrap">
		<div class="content">
			<div class="leftAd"></div>
			<div class="right">
				<div class="content">
					<div class="tab">
						<div @click="go_company_login">企业老板登录</div>
						<div class="current">企业员工登录</div>
					</div>

					<div class="middle_line">
						<div class="middle_p_left">公司名：</div>
						<a-input style="width: 250px" placeholder="输入公司名" v-model="business_name" @pressEnter="go_login" />
					</div>

					<div class="middle_line">
						<div class="middle_p_left">用户名：</div>
						<a-input placeholder="输入公司用户名" v-model="business_user" @pressEnter="go_login" />
					</div>

					<div class="middle_password_line" v-show="type == 5">
						<div class="middle_p_left">密码：</div>
						<a-input-password class="pass" placeholder="输入公司告诉你的员工密码" v-model="business_password" @pressEnter="go_login" />
					</div>

					<!-- <div class="middle_down">
            <a-checkbox
              v-show="type == 5"
              class="middle_d_left"
              :checked="checked"
              @change="rember_password"
            >
              记住密码
            </a-checkbox>
          </div> -->

					<a-button type="primary" class="down_btn" @click="go_login"> 登录 </a-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { user_login } from "@/api/login";
export default {
	data() {
		return {
			type: 5,
			phone: "",
			password: "",
			messagecode: null,
			get_messagecode_state: false,
			checked: true, //是否记住密码

			business_name: "",
			business_user: "",
			business_password: "",
		};
	},
	mounted() {
		if (localStorage.user_password) {
			this.business_name = localStorage.business_name;
			this.business_user = localStorage.business_user;
			this.business_password = localStorage.business_password;
		}
	},
	methods: {
		go_company_login() {
			this.$router.push({ name: "login" });
		},
		change_type(data) {
			this.type = data;
		},
		rember_password(e) {
			this.checked = e.target.checked;
		},
		async go_login() {
			// if (!~navigator.userAgent.indexOf("YangTao")) {
			//   alert("登录参数错误~");
			//   return false;
			// }

			if (this.type == 5) {
				if (this.checked) {
					localStorage.business_name = this.business_name;
					localStorage.business_user = this.business_user;
					localStorage.business_password = this.business_password;
				} else {
					localStorage.business_name = "";
					localStorage.business_user = "";
					localStorage.business_password = "";
				}
			}

			let { data } = await user_login({
				type: 5,
				business_name: this.business_name,
				business_pwd: this.business_password,
				username: this.business_user,
				mac_address: localStorage.mac_address, //用于判断是否为终端白名单中的地址
			});
			if (data.code == 200) {
				localStorage.member = JSON.stringify(data.data.member);
				localStorage.user = JSON.stringify(data.data.user);
				localStorage.token = data.data.token;
				let params = {
					message: "/connection/send_configdata",
					token: data.data.token,
				};
				this.$store.state.websocket.send(this.$encrypt(JSON.stringify(params)));
				this.$router.push({ name: "manage_home" });
			}
		},
	},
};
</script>

<style scoped lang="less">
.login_wrap {
	// background: #919191;
	background: url(../../assets/img/backnew.png) no-repeat center center;
	background-size: 100% 100%;
	margin-left: 40px;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.content {
		display: flex;
		width: 920px;
		height: 540px;

		.leftAd {
			display: flex;
			background: url(../.././/assets/img/leftad.png) no-repeat center center;
			background-size: 100% 100%;
			width: 320px;
			height: 540px;
		}

		.right {
			display: flex;
			flex: 1;
			flex-direction: column;
			background: #fff;
			padding-left: 44px;

			.content {
				display: flex;
				flex-direction: column;
				width: 100%;

				.tab {
					display: flex;
					margin-top: 50px;
					margin-bottom: 25px;

					div {
						margin-right: 60px;
						font-size: 22px;
						font-weight: 500;
						color: #374567;
						position: relative;
						cursor: pointer;

						&.current {
							color: #3b77fa;

							&::after {
								position: absolute;
								content: "";
								bottom: -5px;
								left: 0;
								height: 2px;
								background: #3b77fa;
								width: 100%;
							}
						}
					}
				}

				.middle_line {
					display: flex;
					width: 100%;
					margin-top: 10px;
					flex-direction: column;

					.middle_p_left {
						flex: none;
					}

					input {
						width: 500px;
						margin-top: 12px;
						width: 500px !important;
						height: 50px;
						background: #f8fbff;
						border-radius: 2px;
						border: 0;
					}
				}

				.middle_password_line {
					display: flex;
					width: 100%;
					margin-top: 10px;
					flex-direction: column;

					.middle_p_left {
						flex: none;
					}

					.pass {
						display: flex;
						width: 500px;
						height: 50px;
						border-radius: 2px;
						background: #f8fbff;
						border: 0;
						margin-top: 12px;
						justify-content: center;
						align-items: center;

						::v-deep {
							input {
								border: 0;
								background: none;
							}
						}
					}

					.middle_password_content {
						width: 500px;
						background: #f8fbff;
						display: flex;
						margin-top: 10px;
						justify-content: center;
						align-items: center;

						input {
							display: flex;
							flex: 1;
							height: 50px;
							border-radius: 2px;
							background: #f8fbff;
							border: 0;
						}

						button {
							width: 122px;
							background: none;
							font-size: 14px;
							font-weight: 400;
							color: #bebebe;
							line-height: 20px;
							border: 0;
							height: 50px;
							box-shadow: none;
							position: relative;

							&::after {
								content: "";
								width: 1px;
								height: 20px;
								background: #c9ccd0;
								position: absolute;
								left: 0;
							}
						}
					}
				}

				.middle_down {
					display: flex;
					margin-top: 15px;
					justify-content: space-between;

					.middle_d_right {
						margin-right: 40px;
						color: #4c84ff;
						cursor: pointer;
					}
				}

				.down_btn {
					width: 500px;
					height: 54px;
					background: #3b77fa;
					border-radius: 2px;
					font-size: 18px;
					font-weight: 500;
					margin-top: 30px;
					color: #ffffff;
				}
			}
		}
	}
}
</style>
